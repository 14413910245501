// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import moment from "moment";
import ProductManagementTable, {
  MIN_SHOW_PRODUCTS,
} from "./productManagementTable";
import {
  fetchAllAvailabilityInformation,
  fetchSubscribedNotificationsPerCustomer,
  fetchAvailabilityInformationByPharmaCompany,
  fetchSccInformation,
  hideToast,
} from "../../redux/reducers";
import MoreOrLess from "./moreOrLess";
import {
  getProductsWaitingForAction,
  getAvailabilityUnknown,
  getAvailabilityInformationUpdated,
  getPharmacompanyAvailabilityUpdated,
} from "../../utils/availability/availabilityUtil";
import { Permission, isAllowed } from "../auth";
import { Progress } from "../generic";
import {
  isPharmaCompanyUser,
  isOriolaUser,
} from "../auth/authorization/authorizationRules";
import SearchField from "./searchField";
import SCCFilterSelection from "./sccFilterSelection";
import Toast from "../toast/toast";
import ExportReport from "./exportReport";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
    flexDirection: "column",
  },
  container: {
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(20),
    marginRight: theme.spacing(20),
    padding: theme.spacing(4),
    [theme.breakpoints.down("lg")]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "90%",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  progressIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
    display: "flex",
  },
  searchAndFilterContainer: {
    display: "flex",
    maxWidth: "1000px",
    marginBottom: theme.spacing(4),
  },
  searchColumn: {
    flex: "1 0 40%",
    marginRight: theme.spacing(3),
  },
  disclaimerText: {
    flexBasis: "550px",
    maxWidth: "50%",
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
  },
  filterLabel: {
    color: theme.palette.grey[600],
    fontSize: "14px",
    marginTop: 0,
  },
  sccFilterColumn: {
    flex: "1 0 30%",
    marginRight: theme.spacing(3.5),
  },
  exportButtonWrapper: {
    flex: "1 0 18% ",
    marginTop: theme.spacing(4),
  },
  exportButton: {},
}));

function ProgressSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.progressIconContainer}>
      <Progress show size={100} />
    </div>
  );
}

function ProductManagement() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    availabilityInformation,
    sendingAvailabilityInformation,
    fetchingAvailabilityInformation,
    lastDataUpdate,
  } = useSelector(state => state.availability);
  const { sccInformation } = useSelector(state => state.scc);
  const { notifications } = useSelector(state => state.notification);
  const toast = useSelector(state => state.toast);
  const { customers } = useSelector(state => state.customer);
  const userData = useSelector(state => state.user.userData);
  const { profitCenterMap } = useSelector(state => state.profitCenter);
  const [availabilityUpdateExpanded, setAvailabilityUpdatedExpanded] =
    useState();
  const [availabilityUnknownExpanded, setAvailabilityUnknownExpanded] =
    useState();
  const [
    productsWaitingForActionExpanded,
    setProductsWaitingForActionExpanded,
  ] = useState();
  const [availabilityInfoExpanded, setAvailabilityInfoExpanded] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedScc, setSelectedScc] = useState();

  useEffect(() => {
    // if pharma company user but not oriola user
    if (
      isPharmaCompanyUser(userData) === true &&
      isOriolaUser(userData) === false
    ) {
      const profitCenters = userData.profitCenters || [];
      dispatch(
        fetchAvailabilityInformationByPharmaCompany(profitCenters, searchTerm)
      );
    } else {
      // oriola or pharmacy user
      dispatch(fetchAllAvailabilityInformation(searchTerm));
      dispatch(
        fetchSubscribedNotificationsPerCustomer(userData.organizationIds)
      );
    }
  }, [dispatch, userData, searchTerm]); // eslint-disable-line

  useEffect(() => {
    // fetch SCC information (only on page load)
    dispatch(fetchSccInformation());
  }, [dispatch]);
  const onHideToast = () => {
    dispatch(hideToast());
  };

  const [productsWaitingforAction, setProductsWaitingForAction] = useState(
    getProductsWaitingForAction(availabilityInformation)
  );
  const [availabilityUnknown, setAvailabilityUnknown] = useState(
    getAvailabilityUnknown(availabilityInformation)
  );
  const [availabilityUpdated, setAvailabilityUpdated] = useState(
    getAvailabilityInformationUpdated(availabilityInformation)
  );

  const [
    pharmacompanyAvailabilityUpdated,
    setPharmacompanyAvailabilityUpdated,
  ] = useState(getPharmacompanyAvailabilityUpdated(availabilityInformation));

  const setAllAvailabilityInfo = availabilityInfo => {
    setProductsWaitingForAction(getProductsWaitingForAction(availabilityInfo));
    setAvailabilityUnknown(getAvailabilityUnknown(availabilityInfo));
    setPharmacompanyAvailabilityUpdated(
      getPharmacompanyAvailabilityUpdated(availabilityInfo)
    );
    setAvailabilityUpdated(getAvailabilityInformationUpdated(availabilityInfo));
  };

  const filterByScc = (availabilityInfo, providedScc) => {
    const filteredByScc = availabilityInfo.filter(info =>
      providedScc.profitCenters.includes(info.profitCenter)
    );
    return filteredByScc;
  };

  // Update tables after each update
  useEffect(() => {
    // Update after request finishes
    if (!sendingAvailabilityInformation) {
      if (selectedScc) {
        // if SCC was selected, filter products by the selected SCC's profit centers
        const filteredByScc = filterByScc(availabilityInformation, selectedScc);
        setAllAvailabilityInfo(filteredByScc);
      } else {
        setAllAvailabilityInfo(availabilityInformation);
      }
    }
  }, [
    sendingAvailabilityInformation,
    fetchingAvailabilityInformation,
    availabilityInformation,
    selectedScc,
  ]);

  const compareProductLocalNames = (a, b) => {
    const aLocalizedName = ProductUtil.getLocalizedProductName(
      a,
      i18n.language
    );
    const bLocalizedName = ProductUtil.getLocalizedProductName(
      b,
      i18n.language
    );
    if (!aLocalizedName || !bLocalizedName) {
      return aLocalizedName ? -1 : 1;
    }
    return aLocalizedName.localeCompare(bLocalizedName);
  };

  const sortAvailabilitiesAlphabetically = availabilities => {
    availabilities.sort((a, b) => compareProductLocalNames(a, b));
    return availabilities;
  };

  const getProductManagementTable = (
    products,
    permission,
    title,
    expanded,
    showAvailabilityLabels,
    showEstimationInWeeks
  ) => (
    <ProductManagementTable
      availabilityInfo={products}
      notifications={notifications}
      profitCenterMap={profitCenterMap}
      customers={customers}
      title={title}
      permission={permission}
      productsWaitingForAction={productsWaitingforAction}
      availabilityUnknown={availabilityUnknown}
      availabilityUpdated={availabilityUpdated}
      expanded={expanded}
      showAvailabilityLabels={showAvailabilityLabels}
      showEstimationInWeeks={showEstimationInWeeks}
    />
  );

  const renderProductManagementByRole = () => {
    // Render Oriola role view
    if (isAllowed(userData, Permission.MANAGEMENT_ORIOLA)) {
      return (
        <div>
          <MoreOrLess
            enabled={productsWaitingforAction.length > MIN_SHOW_PRODUCTS}
            text={t("productsWaitingforAction")}
            count={productsWaitingforAction.length}
            value={productsWaitingForActionExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded =>
              setProductsWaitingForActionExpanded(expanded)
            }
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(productsWaitingforAction),
              Permission.MANAGEMENT_ORIOLA,
              null,
              productsWaitingForActionExpanded,
              false
            )}
          </MoreOrLess>

          <MoreOrLess
            enabled={availabilityUnknown.length > MIN_SHOW_PRODUCTS}
            text={t("availabilityUnknown")}
            count={availabilityUnknown.length}
            value={availabilityUnknownExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded =>
              setAvailabilityUnknownExpanded(expanded)
            }
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(availabilityUnknown),
              Permission.MANAGEMENT_ORIOLA,
              null,
              availabilityUnknownExpanded,
              false
            )}
          </MoreOrLess>

          <MoreOrLess
            enabled={availabilityUpdated.length > MIN_SHOW_PRODUCTS}
            text={t("availabilityInformationUpdated")}
            count={availabilityUpdated.length}
            value={availabilityUpdateExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded =>
              setAvailabilityUpdatedExpanded(expanded)
            }
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(availabilityUpdated),
              Permission.MANAGEMENT_ORIOLA,
              null,
              availabilityUpdateExpanded,
              false
            )}
          </MoreOrLess>
        </div>
      );
    }

    // Render Pharmacompany role view
    if (isAllowed(userData, Permission.MANAGEMENT_PHARMACOMPANY)) {
      return (
        <div>
          <MoreOrLess
            enabled={productsWaitingforAction.length > MIN_SHOW_PRODUCTS}
            text={t("productsWaitingforAction")}
            count={productsWaitingforAction.length}
            value={productsWaitingForActionExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded =>
              setProductsWaitingForActionExpanded(expanded)
            }
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(productsWaitingforAction),
              Permission.MANAGEMENT_PHARMACOMPANY,
              null,
              productsWaitingForActionExpanded,
              true
            )}
          </MoreOrLess>

          <MoreOrLess
            enabled={
              pharmacompanyAvailabilityUpdated.length > MIN_SHOW_PRODUCTS
            }
            text={t("availabilityInformationUpdated")}
            count={pharmacompanyAvailabilityUpdated.length}
            value={availabilityUpdateExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded =>
              setAvailabilityUpdatedExpanded(expanded)
            }
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(
                pharmacompanyAvailabilityUpdated
              ),
              Permission.MANAGEMENT_PHARMACOMPANY,
              null,
              availabilityUpdateExpanded,
              false
            )}
          </MoreOrLess>
        </div>
      );
    }

    // Render Pharmacy & Authority view role view
    if (isAllowed(userData, Permission.MANAGEMENT_PHARMACY)) {
      return (
        <div>
          <MoreOrLess
            enabled={availabilityInformation != null}
            text=""
            count={availabilityInformation.length}
            value={availabilityInfoExpanded}
            noItemsText={t("noProductsInList")}
            onToggleExpand={expanded => setAvailabilityInfoExpanded(expanded)}
          >
            {getProductManagementTable(
              sortAvailabilitiesAlphabetically(availabilityInformation),
              Permission.MANAGEMENT_PHARMACY,
              null,
              availabilityInfoExpanded,
              false,
              !isAllowed(userData, Permission.MANAGEMENT_AUTHORITY_VIEW)
            )}
          </MoreOrLess>
        </div>
      );
    }
    return null;
  };

  const getLastUpdated = () => {
    if (fetchingAvailabilityInformation && !lastDataUpdate) {
      return `${t("productManagementTable.loading")} ..`;
    }
    if (lastDataUpdate) {
      return moment(lastDataUpdate).format("HH:mm DD.MM.YYYY");
    }
    return "-";
  };

  const renderSearch = () => (
    <div className={classes.searchAndFilterContainer}>
      {isAllowed(userData, Permission.MANAGEMENT_ORIOLA) && (
        <div className={classes.sccFilterColumn}>
          <Typography className={classes.filterLabel}>{t("filter")}</Typography>
          <SCCFilterSelection
            sccs={sccInformation}
            onChange={selection => setSelectedScc(selection)}
            selectedScc={selectedScc}
            disabled={sccInformation.length === 0}
          />
        </div>
      )}
      <div className={classes.searchColumn}>
        <Typography className={classes.filterLabel} />
        <SearchField
          placeholder={t("searchDescription")}
          onSearch={term => setSearchTerm(term)}
        />
      </div>
      <ExportReport
        classes={classes}
        searchTerm={searchTerm}
        selectedScc={selectedScc}
      />
    </div>
  );

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant="h4">{t("productManagement")}</Typography>
          <Typography variant="body1" className={classes.disclaimerText}>
            {t("availabilityDisclaimer")} {t("availabilityLastUpdated")}:{" "}
            {getLastUpdated()}.
          </Typography>
        </div>
      </div>

      {renderSearch()}
      {fetchingAvailabilityInformation ? (
        <ProgressSpinner />
      ) : (
        renderProductManagementByRole()
      )}
      <Toast
        open={toast.open}
        text={toast.text}
        variant={toast.variant}
        autoHideDuration={toast.autoHideDuration}
        onClose={onHideToast}
      />
    </Paper>
  );
}

ProductManagement.propTypes = {};
ProductManagement.defaultProps = {};

export default ProductManagement;
