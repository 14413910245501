const isDeliveryBlockCodeZ7 = product =>
  product.companies[0].deliveryBlockCode === "Z7" || false;

export const getProductsWaitingForAction = info =>
  info.filter(
    x => !x.availabilityDate && !x.information && !isDeliveryBlockCodeZ7(x)
  );

export const getAvailabilityUnknown = info =>
  info.filter(
    x => !x.availabilityDate && x.information && !isDeliveryBlockCodeZ7(x)
  );

export const getAvailabilityInformationUpdated = info =>
  info.filter(x => x.availabilityDate || isDeliveryBlockCodeZ7(x));

export const getPharmacompanyAvailabilityUpdated = info =>
  info.filter(x => x.availabilityDate || x.information);
